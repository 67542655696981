import React, {useState, useEffect} from 'react';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import {Button, Table} from 'antd'; // Importa Button y Table de Ant Design
import './CubaMap.css'; // Asegúrate de tener un archivo CSS si es necesario

const CubaMap = () => {
    const [map, setMap] = useState(null);
    const [points, setPoints] = useState([]);
    const [markers, setMarkers] = useState([]); // Estado para almacenar los marcadores

    useEffect(() => {
        const mapInstance = L.map('map', {
            center: [21.5, -77.0], // Centrar en Cuba
            zoom: 6,
        });

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            maxZoom: 19,
        }).addTo(mapInstance);

        setMap(mapInstance);

        // Cleanup on unmount
        return () => {
            mapInstance.remove();
        };
    }, []);

    useEffect(() => {
        if (map) {
            // Añadir evento de doble clic para añadir puntos
            map.on('dblclick', (event) => {
                const {lat, lng} = event.latlng;
                const newPoint = {lat, lng};

                // Define el icono del marcador
                const markerIcon = L.icon({
                    iconUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png',
                    shadowUrl: 'https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png',
                    iconSize: [25, 41],
                    iconAnchor: [12, 41],
                    shadowSize: [41, 41],
                });

                // Añadir marcador al mapa
                const marker = L.marker([lat, lng], {icon: markerIcon}).addTo(map);
                setMarkers((prevMarkers) => [...prevMarkers, marker]); // Almacenar el marcador
                setPoints((prevPoints) => [...prevPoints, newPoint]);
            });
        }
    }, [map]);

    // Función para manejar el envío de coordenadas
    const handleSendCoordinates = () => {
        console.log('Coordenadas seleccionadas:', points);
    };

    // Función para limpiar el listado de coordenadas y eliminar los marcadores
    const handleClearCoordinates = () => {
        setPoints([]);
        markers.forEach(marker => map.removeLayer(marker)); // Eliminar todos los marcadores del mapa
        setMarkers([]); // Limpiar el estado de los marcadores
    };

    // Configuración de las columnas de la tabla
    const columns = [
        {
            title: 'Latitud',
            dataIndex: 'lat',
            key: 'lat',
        },
        {
            title: 'Longitud',
            dataIndex: 'lng',
            key: 'lng',
        },
    ];

    return (
        <div style={{display: 'flex'}}>
            <div id="map" style={{height: '100vh', width: '70%'}}></div>
            <div style={{padding: '20px', width: '30%', overflow: 'auto', maxHeight: '100vh'}}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    Puntos Seleccionados
                </p>
                <Table
                    dataSource={points.map((point, index) => ({key: index, lat: point.lat, lng: point.lng}))}
                    columns={columns}
                    pagination={false}
                    bordered
                    scroll={{y: 400}} // Ajusta la altura del scroll según necesites
                    style={{boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)'}} // Agregar sombra a la tabla
                />
                <Button
                    type="primary"
                    onClick={handleSendCoordinates}
                    style={{marginTop: '20px'}}
                >
                    Enviar Coordenadas
                </Button>
                <Button
                    type="default"
                    danger
                    onClick={handleClearCoordinates}
                    style={{marginTop: '20px', marginLeft: '10px'}} // Añadir margen
                >
                    Limpiar Coordenadas
                </Button>
            </div>
        </div>
    );
};

export default CubaMap;
