import React, {useContext, useEffect, useState} from 'react';
import {
    MenuFoldOutlined,
    MenuUnfoldOutlined,
} from '@ant-design/icons';
import {Layout, Menu, Button, Row, Col, Select} from 'antd';
import { BrowserRouter } from "react-router-dom";
import { LoginContext } from "../components/seguridad/servicios/LoginServicios";
import UsuarioDropdown from "../generales/SessionDropdown";
import RoutersSystem from './Routers';
import MenuPrincipal from '../generales/MenuPricipal';
import {useTranslation} from "react-i18next";

const { Header, Sider, Content } = Layout;

const Home = () => {
    const { logout } = useContext(LoginContext);

    if (!sessionStorage.getItem('idusuario') ||
        !sessionStorage.getItem('token') ||
        !sessionStorage.getItem('info_user')) {
        logout();
    }

    // const [idioma, setIdioma] = useState("es");
    //
    // const { t, i18n } = useTranslation();
    //
    // // Cambiar el idioma
    // const changeLanguage = (lng) => {
    //     i18n.changeLanguage(lng);
    //     setIdioma(lng);
    //     sessionStorage.setItem("idioma", lng);
    // };
    //
    // useEffect(() => {
    //     const userLang = sessionStorage.getItem("idioma") || navigator.language || navigator.userLanguage; // Detectar idioma
    //     const preferredLang = userLang.startsWith('es') ? 'es' : 'en'; // Ajustar a 'es' o 'en'
    //     changeLanguage(preferredLang); // Cambiar idioma
    // }, []);

    const [collapsed, setCollapsed] = useState(false);

    return (
        <BrowserRouter>
            <Layout style={{ minHeight: '100vh' }}>
                <Sider trigger={null} collapsible collapsed={collapsed} style={{ background: '#001529' }}>
                    <MenuPrincipal collapsed={collapsed} />
                </Sider>
                <Layout>
                    <Header className="header-layout" style={{ backgroundColor: '#61768B', padding: 0 }}>
                        <Row justify="space-between" align="middle" style={{ height: 54 }}>
                            <Col>
                                <Button
                                    type="text"
                                    icon={collapsed ? <MenuUnfoldOutlined style={{ color: "#FFFFFF" }} /> : <MenuFoldOutlined style={{ color: "#FFFFFF" }} />}
                                    onClick={() => setCollapsed(!collapsed)}
                                    style={{
                                        fontSize: '20px',
                                        width: 180,
                                        height: 54,
                                        color: "white",
                                        display: 'flex',
                                        alignItems: 'center',
                                    }}
                                >
                                    SmartDelivery
                                </Button>
                            </Col>
                            {/*<Col>*/}
                            {/*    <div style={{ textAlign: "center", marginTop: "10px"}}>*/}

                            {/*        <Select*/}
                            {/*            value={"es"}*/}
                            {/*            onChange={changeLanguage}*/}
                            {/*            options={[*/}
                            {/*                {value: "es", label: "Español"},*/}
                            {/*                {value: "en", label: "Inglés"},*/}
                            {/*            ]}*/}
                            {/*            style={{width: "120px"}}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</Col>*/}
                            <Col>
                                <UsuarioDropdown/>
                            </Col>
                        </Row>
                    </Header>
                    <Content
                        style={{
                            // margin: '0 0px',
                            // padding: 0,
                            minHeight: 280,
                            backgroundColor: 'white',
                        }}
                    >
                    <RoutersSystem />
                    </Content>
                </Layout>
            </Layout>
        </BrowserRouter>
    );
};

export default Home;
