import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Button, Table, message, Form, Modal, Spin, Space, Row, Col, Tag, Tooltip, Input} from 'antd';
import {Link, useNavigate} from "react-router-dom";
import {MdAddHome} from "react-icons/md";
import {UserAddOutlined, UserDeleteOutlined, SearchOutlined, EyeOutlined} from '@ant-design/icons';
import {
    AiFillDelete,
    AiFillEdit,
} from "react-icons/ai";
import {IoReload} from "react-icons/io5";
import Search from "antd/es/input/Search";
import _ from "lodash";



export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

// const { Option } = Select;


const Persona = () => {

    if(sessionStorage.getItem('user_mod'))
       sessionStorage.removeItem('user_mod');

    const [bottom, setBottom] = useState('bottomCenter');

    const [original, setOriginal] = useState([]);
    const [data, setData] = useState([]);

    const [form] = Form.useForm();
    const [formSearch] = Form.useForm();

    const navigate = useNavigate();

    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState("Cargando datos...");

    const [messageApi, contextHolder] = message.useMessage();

    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading/*, setLoading*/] = useState(false);

    const [selectedRow, setSelectedRow] = useState([]); // Estado para la fila seleccionada
    const [isVerVisible, setIsVerVisible] = useState(false); // Estado del modal

    const [searchCIPasaporteValue, setSearchCIPasaporte] = useState(''); // Estado para controlar el valor del input
    const [searchDireccionValue, setSearchDireccion] = useState(''); // Estado para controlar el valor del input

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 10,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
      };

    useEffect(() => {
        recargar();
    }, []);

    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const columns = [
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>Nombre</p>,
            dataIndex: 'nombre',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.nombre), _.isEqual),
            onFilter: (value, record) => record.nombre.startsWith(value),
        },
        {
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>Primer Apellido</p>,
            dataIndex: 'papell',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.papell), _.isEqual),
            onFilter: (value, record) => record.papell.startsWith(value),
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>Segundo Apellido</p>,
            dataIndex: 'sapell',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.sapell), _.isEqual),
            onFilter: (value, record) => record.sapell.startsWith(value),
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>CI / Pasaporte</p>,
            dataIndex: 'carnet',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.carnet), _.isEqual),
            onFilter: (value, record) => record.carnet.startsWith(value),
        },{
            title: <p style={{
                margin: "0px 0",
                fontSize: "15px",
                fontWeight: "bold",
                color: "#2c3e50",
                fontFamily: "'Roboto', sans-serif",
                textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
            }}>Teléfono</p>,
            dataIndex: 'telefono',
            width: 200,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.telefono), _.isEqual),
            onFilter: (value, record) => record.telefono.startsWith(value),
        }
    ];

    const recargar = () => {
        setSearchCIPasaporte(''); // Resetea el valor del campo de búsqueda
        setSearchDireccion(''); // Resetea el valor del campo de búsqueda

        setSpinTitle("Cargando datos...");
        setSpinning(true);
        setSelectedRowKeys([]);
        let aux = [];

        let start = 0;
        let limit = 1000;

        Axios.post("sd/getPersonas",
            {
                // "start": start,
                // "limit": limit,
                // "filtro":{
                //     "idrol": 1
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);
            if(response.data.data.length>0){
                for(let i=0;i<response.data.data.length;i++){
                    aux.push({
                        key:response.data.data[i].idpersona,
                        idpersona:response.data.data[i].idpersona,
                        nombre:response.data.data[i].nombre,
                        papell:response.data.data[i].papell,
                        sapell:response.data.data[i].sapell,
                        carnet:response.data.data[i].carnet,
                        telefono:response.data.data[i].telefono,
                        direccion:response.data.data[i].direccion,
                        correo:response.data.data[i].correo,
                    });
                }
            }

            setData(aux);
            setOriginal(aux);
        }).catch(error => {
            setSpinning(false);
        });

    };

    const getInfoForm = async (id) => {
        for(let i=0; i < data.length; i++){
            if(data[i].idpersona === id){
                return data[i];
            }
        }
        return [];
    };

    const modificar = async () => {
        form.resetFields();
        const row = await getInfoForm(selectedRowKeys[0]);
        // console.log(selectedRowKeys[0])
        sessionStorage.setItem('user_mod', JSON.stringify(row));
    };

    const eliminar = (e) => {
        Modal.confirm({
            title: 'Eliminar datos!',
            cancelText:'Cancelar',
            okText:'Aceptar',
            okType:'primary',
            content: (
                <div>
                    <span >¿Desea eliminar los datos seleccionados?</span>
                </div>
            ),
            onOk() {
                setSpinTitle("Eliminando datos...");
                setSpinning(true);
                Axios.post("sd/eliminarPersona",
                    {
                        "idpersona": selectedRowKeys
                    },
                    {
                        headers: headers
                    }
                ).then(response => {
                    setSpinning(false);
                    if(response.data.success){
                        messageApi.open({
                            type: 'success',
                            content: response.data.message,
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                        recargar();
                    }else{
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);
                    }
                })
                    .catch(error => {
                        setSpinning(false);
                        messageApi.open({
                            type: 'error',
                            content: 'Error al guardar los datos',
                            duration: 0,
                        });

                        setTimeout(messageApi.destroy, 2500);

                    });
            },
        });
    };

    const onSearch = (value, _e, info) => {
        filterLocal(value);
    };

    const filterLocal = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.carnet.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };
    const onSearchDireccion = (value, _e, info) => {
        filterLocal(value);
    };
    const filterLocalDireccion = (value) => {
        if(value === "" || value === null){
            setData(original);
        }else{
            let aux = [];
            original.forEach(function(option) {
                if (option.direccion.toUpperCase().indexOf(value.toUpperCase()) !== -1) {
                    aux.push(option);
                }
            });
            setData(aux);
        }
    };


    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange,
    };
    const hasSelected = selectedRowKeys.length > 0;

    const selectRowUpdate  = async (record) => {
        setSelectedRowKeys([record.key]);
        form.resetFields();
        let row = [];
        for(let i=0;i<data.length;i++){
            if(data[i].idpersona === record.key){
                row = data[i];
            }
        }

        sessionStorage.setItem('user_mod', JSON.stringify(row));
        navigate(`/edittipotransporte/${record.key}`)

    };
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [searchParams, setSearchParams] = useState({});

    const showModal = () => {
        formSearch.resetFields();
        setIsModalVisible(true);
    };

    const handleFormChange = (changedValues, allValues) => {
        allValues = {"filtros":
                        {
                            nombre: allValues.nombre,
                            papell: allValues.papell,
                            sapell: allValues.sapell,
                            carnet: allValues.carnet,
                            telefono: allValues.telefono,
                            direccion: allValues.direccion,
                            correo: allValues.correo
                        }
                    };
        //console.log(allValues);
        setSearchParams(allValues);  // Actualizar los parámetros de búsqueda conforme se llena el formulario
    };

    const handleOk = () => {
        setSpinTitle("Buscando...");
        setSpinning(true);
        Axios.post('sd/getPersonas', searchParams, { headers })  // Se envían los parámetros de búsqueda
            .then(response => {
                setSpinning(false);
                if (response.data.data.length > 0) {
                    const aux = response.data.data.map(persona => ({
                        key: persona.idpersona,
                        idpersona: persona.idpersona,
                        nombre: persona.nombre,
                        papell: persona.papell,
                        sapell: persona.sapell,
                        carnet: persona.carnet,
                        telefono: persona.telefono,
                        direccion: persona.direccion,
                        correo: persona.correo
                    }));
                    setData(aux);  // Actualizar la tabla con los resultados de búsqueda
                } else {
                    message.warning('No se encontraron resultados.');
                    setData([]);
                }
                setIsModalVisible(false);  // Cerrar el modal después de realizar la búsqueda
            })
            .catch(() => {
                setSpinning(false);
                message.error('Error al realizar la búsqueda.');
            });
    };


    const handleCancel = () => {
        formSearch.resetFields();
        setIsModalVisible(false);
    };

    const showModalVer = async () => {
        const row = await getInfoForm(selectedRowKeys[0]);
        setSelectedRow(row); // Guardar la fila seleccionada
        setIsVerVisible(true); // Mostrar el modal
    };

    const handleCancelVer = () => {
        setIsVerVisible(false); // Ocultar el modal
    };


    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>
            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    Listado de Personas
                </p>

                {contextHolder}
                <div
                    style={{
                        marginBottom: 10,
                    }}
                >
                    {contextHolder}
                    <Space direction='horizontal'>
                        <Link to="/addpersona">
                            <Tooltip title="Adicionar">
                                <Button style={{marginRight: "0px", marginLeft: "10px"}} type="primary" shape="circle"
                                        className="button" icon={<UserAddOutlined/>} disabled={loading}>

                                </Button>
                            </Tooltip>
                        </Link>
                        <Link to={`/editpersona/${selectedRowKeys[0]}`}>
                            <Tooltip title="Modificar">
                                <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                        className="button" icon={<AiFillEdit/>} onClick={modificar}
                                        disabled={loading || selectedRowKeys.length !== 1}>

                                </Button>
                            </Tooltip>
                        </Link>
                        <Tooltip title="Ver detalles">
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<EyeOutlined />} onClick={showModalVer}
                                    disabled={!hasSelected || loading || selectedRowKeys.length !== 1}>

                            </Button>
                        </Tooltip>
                        <Tooltip title="Eliminar">
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle" danger
                                    className="button" icon={<UserDeleteOutlined/>} onClick={eliminar}
                                    disabled={!hasSelected || loading}>

                            </Button>
                        </Tooltip>
                        <Tooltip title="Buscar Personas">
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<SearchOutlined/>} onClick={showModal}></Button>
                        </Tooltip>
                        <Tooltip title="Actualizar">
                            <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                    className="button" icon={<IoReload/>} onClick={recargar} loading={loading}>

                            </Button>
                        </Tooltip>

                        <Search
                            placeholder="CI / Pasaporte"
                            enterButton
                            style={{
                                width: 230,
                            }}
                            size="middle"
                            onSearch={onSearch}
                            value={searchCIPasaporteValue} // El valor del input es controlado por el estado
                            onChange={(e) => {
                                setSearchCIPasaporte(e.target.value); // Actualiza el estado con lo que se escribe
                                filterLocal(e.target.value);
                            }}
                        />
                        <Search
                            placeholder="Dirección"
                            enterButton
                            style={{
                                width: 230,
                            }}
                            size="middle"
                            onSearch={onSearchDireccion}
                            value={searchDireccionValue} // El valor del input es controlado por el estado
                            onChange={(e) => {
                                setSearchDireccion(e.target.value); // Actualiza el estado con lo que se escribe
                                filterLocalDireccion(e.target.value);
                            }}
                        />
                    </Space>

                    <Modal
                        title={<div style={{ textAlign: 'center', width: '100%' }}>Buscar Personas</div>}
                        open={isModalVisible}
                        //onOk={handleOk}
                        onCancel={handleCancel}
                        footer={
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <Button onClick={handleCancel} style={{ marginRight: 8 }}>
                                    Cancelar
                                </Button>
                                <Button type="primary" onClick={handleOk}>
                                    Aceptar
                                </Button>
                            </div>
                        }
                    >
                        <Form form={formSearch} layout="vertical" onValuesChange={handleFormChange}>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Nombre" name="nombre">
                                        <Input placeholder="Ingrese el nombre" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="CI / Pasaporte" name="carnet">
                                        <Input placeholder="Ingrese el CI o Pasaporte" />
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Primer Apellido" name="papell">
                                        <Input placeholder="Ingrese el primer apellido" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Correo" name="correo">
                                        <Input placeholder="Ingrese el correo" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={12}>
                                    <Form.Item label="Segundo Apellido" name="sapell">
                                        <Input placeholder="Ingrese el segundo apellido" />
                                    </Form.Item>

                                </Col>
                                <Col span={12}>
                                    <Form.Item label="Teléfono" name="telefono">
                                        <Input placeholder="Ingrese el teléfono" />
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col span={24}>
                                    <Form.Item label="Dirección" name="direccion">
                                        <Input placeholder="Ingrese la dirección" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Form>
                    </Modal>

                    <Modal
                        title={<div style={{ textAlign: 'center', width: '100%' }}>Detalles de la Persona</div>}
                        open={isVerVisible}
                        onCancel={handleCancelVer}
                        footer={
                            <div style={{ textAlign: 'center', width: '100%' }}>
                                <Button type="primary" onClick={handleCancelVer} style={{ marginRight: 8 }}>
                                    OK
                                </Button>
                            </div>
                        }
                    >
                        {selectedRow && (
                            <div>
                                <p><strong>Nombre:</strong> {selectedRow.nombre}</p>
                                <p><strong>Primer Apellido:</strong> {selectedRow.papell}</p>
                                <p><strong>Segundo Apellido:</strong> {selectedRow.sapell}</p>
                                <p><strong>CI / Pasaporte:</strong> {selectedRow.carnet}</p>
                                <p><strong>Teléfono:</strong> {selectedRow.telefono}</p>
                                <p><strong>Correo:</strong> {selectedRow.correo}</p>
                                <p><strong>Dirección:</strong> {selectedRow.direccion}</p>
                            </div>
                        )}
                    </Modal>


                </div>

                <Row>
                    <Col span={24}>

                        <Table size="small"
                            // scroll={{
                            //     y: 400,
                            // }}
                               bordered={true}
                               pagination={{
                                   position: [bottom],
                                   current: pagination.current,
                                   pageSize: pagination.pageSize,
                                   showSizeChanger: true,
                                   pageSizeOptions: ['10', '20', '50', '100'],
                                   showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                               }}
                               rowSelection={rowSelection}
                               columns={columns}
                               dataSource={data}
                               onChange={handleTableChange} // Este método maneja el cambio de paginación
                               onRow={(record) => {
                                   return {
                                       onDoubleClick: async () => {
                                           //selectRowUpdate(record);
                                       }
                                   }
                               }}
                               expandable={{
                                   expandedRowRender: (record) => (
                                       <p
                                           style={{
                                               margin: 0,
                                               marginLeft: 117,
                                               color: '#38516D'
                                           }}
                                       >
                                           <b>Correo : </b>
                                           {record.correo}

                                           <br></br>

                                           <b>Dirección : </b>
                                           {record.direccion}

                                       </p>


                                   ),
                                   rowExpandable: (record) => record.direccion !== 'Not Expandable',
                               }}
                        />

                    </Col>
                </Row>




            </div>
        </Spin>
    );
};
export default Persona;