import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Table, Input, Button, Form, DatePicker, Modal, Space, Tooltip, Spin, message, Row, Col} from 'antd';
import moment from 'moment';
import {FormattedMessage} from "react-intl";
// import {SearchOutlined} from "react-icons/io5";
import {CloseOutlined, ImportOutlined, SearchOutlined, CloudDownloadOutlined} from '@ant-design/icons';
import {IoReload} from "react-icons/io5";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import StatusFacturas from "./StatusFacturas";

const { RangePicker } = DatePicker;

export const Axios = axios.create({
    baseURL: process.env.REACT_APP_APPI_ADDRESS
});

function Facturas() {

    const {t, i18n} = useTranslation();
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [form] = Form.useForm();
    const [formBuscarFactura] = Form.useForm();
    const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
    const [isModalBuscarFacturaOpen, setIsModalBuscarFacturaOpen] = useState(false); // Estado para controlar el modal
    const [spinning, setSpinning] = useState(false);
    const [spinTitle, setSpinTitle] = useState(t('global.loading'));
    const [bottom, setBottom] = useState('bottomCenter');
    const [messageApi, contextHolder] = message.useMessage();

    const [isModalOpenDetalles, setIsModalOpenDetalles] = useState(false); // Estado para controlar el modal
    const [selectedInvoice, setSelectedInvoice] = useState(null); // Almacenar la factura seleccionada


    const headers = {
        'accept': '*/*',
        'Authorization': 'Bearer ' + sessionStorage.getItem('token'),
        'Content-Type': 'application/json',
    };

    useEffect(() => {
        recargar();
    }, []);

    const fetchShippingData = async (values) => {

        const { enterprise, dates, status } = values;

        let inidate = null;
        let enddate = null;

        if (dates && dates.length === 2) {
            // Verifica que dates tenga exactamente dos fechas
            const [startDate, finishDate] = dates;
            if (startDate && finishDate) {
                inidate = startDate.format('YYYY-MM-DD');
                enddate = finishDate.format('YYYY-MM-DD');
            }
        }

        // console.log('Inidate:', inidate); // Para depuración
        // console.log('Enddate:', enddate); // Para depuración

        const url = 'https://www.solved1.com/api/appi/playa-dev/shippingdata/';
        const params = {
            apikey: '6I77Z9V01XV7IE3U5BM7XQ4X3D3PNK',
            data: {
                enterprise: enterprise,
                inidate: inidate,
                enddate: enddate,
                status: status || '',
            },
        };

        setError(null);

        try {
            setSpinTitle(t('global.loading'));
            setSpinning(true);
            const response = await axios.get(url, { params: { apikey: params.apikey, data: JSON.stringify(params.data) } });
            const rawData = response.data;

            if (!rawData || typeof rawData !== 'object') {
                throw new Error('Datos inválidos recibidos');
            }


            const formattedData = Object.keys(rawData).map(code => ({
                code,
                ...rawData[code],
            }));
            // console.log(formattedData);

            const invoiceData = [];

            // Recorrer el resultado de la API
            formattedData.forEach(invoice => {
                const formattedInvoice = {
                    code: invoice.code,
                    rdatereserve: invoice.rdatereserve,
                    cname: invoice.cname,
                    cidentity: invoice.cidentity,
                    address: invoice.address,
                    cprovince: invoice.cprovince,
                    cmunicipality: invoice.cmunicipality,
                    sname: invoice.sname,
                    products: invoice.products // Asumiendo que los productos ya tienen el formato correcto
                };

                // Agregar la factura formateada al array invoiceData
                invoiceData.push(formattedInvoice);
            });

            // console.log({invoiceData});
            let message;

            Axios.post(
                '/sd/addInvoiceProducts',
                invoiceData,
                {
                    headers: headers
                }
            ).then(response => {
                // console.log(response.data.message);
                setSpinning(false);
                message = response.data.message;
                if(response.data.success){
                    messageApi.open({
                        type: 'success',
                        content: message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);
                    recargar();
                }else{
                    messageApi.open({
                        type: 'error',
                        content: message,
                        duration: 0,
                    });

                    setTimeout(messageApi.destroy, 2500);
                }
            }).catch(error => {
                setSpinning(false);
                messageApi.open({
                    type: 'error',
                    content: message,
                    duration: 0,
                });

                setTimeout(messageApi.destroy, 2500);
            });


            // setData(formattedData);
        } catch (error) {
            setError('Error fetching data');
        } finally {
            //setLoading(false);
            setSpinning(false);
            recargar();
        }
    };

    const recargar = () => {

        setSpinTitle(t('global.loading'));
        setSpinning(true);
        // setSelectedRowKeys([]);
        let aux = [];

        // let start = 0;
        // let limit = 1000;

        Axios.post("sd/getFacturas",
            {
                // "start": start,
                // "limit": limit,
                // "filtro": {
                //     "idrol": 1
                // }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);

            // Asumiendo que response.data.data es un array
            const rawData = response.data.data;

            // No necesitas usar Object.keys, solo asigna rawData directamente
            const formattedData = rawData.map(item => ({
                code: item.code,
                rdatereserve: item.rdatereserve,
                cname: item.cname,
                cidentity: item.cidentity,
                address: item.address,
                cprovince: item.cprovince,
                cmunicipality: item.cmunicipality,
                sname: item.sname,
                products: JSON.parse(item.products)  // Asegúrate de que el valor sea un array
            }));

            // console.log(formattedData);
            setData(formattedData);

        }).catch(error => {
            setSpinning(false);
        });

    };
    const filterData = data => formatter => data.map( item => ({
        text: formatter(item),
        value: formatter(item)
    }));

    const showModalVerDetalles = (record) => {
        setSelectedInvoice(record); // Guardar la factura seleccionada
        setIsModalOpenDetalles(true); // Mostrar el modal
    };

    const handleCancelVerDetalles = () => {
        setIsModalOpenDetalles(false); // Cerrar el modal
        setSelectedInvoice(null); // Limpiar la factura seleccionada
    };

    const columns = [
        {
            title: t('invoice.col_identificador'),
            dataIndex: 'code',
            key: 'code',
            width: 150,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.code), _.isEqual),
            onFilter: (value, record) => record.code.startsWith(value),
            sorter: (a, b) => a.code.localeCompare(b.code),
            render: (text, record) => (
                <Button type="link" onClick={() => showModalVerDetalles(record)}>
                    {text}
                </Button>
            )
        },{
            title: t('invoice.col_fecha_reserva'),
            dataIndex: 'rdatereserve',
            key: 'rdatereserve',
            width: 150,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.rdatereserve), _.isEqual),
            onFilter: (value, record) => record.rdatereserve.startsWith(value),
            sorter: (a, b) => a.rdatereserve.localeCompare(b.rdatereserve)
        },
        {
            title: t('invoice.col_destinatario'),
            dataIndex: 'cname',
            key: 'cname',
            width: 300,
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cname), _.isEqual),
            onFilter: (value, record) => record.cname.startsWith(value),
            sorter: (a, b) => a.cname.localeCompare(b.cname)
        },
        {
            title: t('invoice.col_destinatario'),
            dataIndex: 'cidentity',
            key: 'cidentity',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cidentity), _.isEqual),
            onFilter: (value, record) => record.cidentity.startsWith(value),
            sorter: (a, b) => a.cidentity.localeCompare(b.cidentity)
        },
        {
            title: t('invoice.col_direccion'),
            dataIndex: 'address',
            key: 'address',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.address), _.isEqual),
            onFilter: (value, record) => record.address.startsWith(value),
            sorter: (a, b) => a.address.localeCompare(b.address)
        },
        {
            title: t('invoice.col_provincia'),
            dataIndex: 'cprovince',
            key: 'cprovince',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cprovince), _.isEqual),
            onFilter: (value, record) => record.cprovince.startsWith(value),
            sorter: (a, b) => a.cprovince.localeCompare(b.cprovince)
        },
        {
            title: t('invoice.col_municipio'),
            dataIndex: 'cmunicipality',
            key: 'cmunicipality',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.cmunicipality), _.isEqual),
            onFilter: (value, record) => record.cmunicipality.startsWith(value),
            sorter: (a, b) => a.cmunicipality.localeCompare(b.cmunicipality)
        },
        {
            title: t('invoice.col_remitente'),
            dataIndex: 'sname',
            key: 'sname',
            filterSearch: true,
            filters: _.uniqWith(filterData(data)(i => i.sname), _.isEqual),
            onFilter: (value, record) => record.sname.startsWith(value),
            sorter: (a, b) => a.sname.localeCompare(b.sname)
        },
    ];

    const productColumns = [
        {
            title: t('invoice.col_nombre_producto'),
            dataIndex: 'namegood',
            key: 'namegood',
        },
        {
            title: t('invoice.col_cantidad'),
            dataIndex: 'quantity',
            key: 'quantity',
        },
        {
            title: t('invoice.col_peso'),
            dataIndex: 'weight',
            key: 'weight',
        },{
            title: t('invoice.col_longitud'),
            dataIndex: 'length',
            key: 'length',
        },{
            title: t('invoice.col_ancho'),
            dataIndex: 'width',
            key: 'width',
        },{
            title: t('invoice.col_alto'),
            dataIndex: 'height',
            key: 'height',
        },
        {
            title: t('invoice.col_dimensiones'),
            render: (text, record) => `${record.length} x ${record.width} x ${record.height} (cm)`,
            key: 'dimensions',
        },
    ];

    const [pagination, setPagination] = useState({
        current: 1,
        pageSize: 20,
        total: data.length, // Asegúrate de tener el total de elementos aquí
    });

    const handleTableChange = (newPagination) => {
        setPagination({
            ...pagination,
            current: newPagination.current,
            pageSize: newPagination.pageSize,
        });
    };

    const expandedRowRender = (record) => {
        return <Table columns={productColumns} dataSource={record.products} pagination={false} rowKey="namegood" />;
    };

    const onFinish = (values) => {
        fetchShippingData(values);
        setIsModalOpen(false); // Cerrar el modal después de buscar
    };

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleCancel = () => {
        form.resetFields();
        setIsModalOpen(false); // Cerrar el modal si se cancela
    };

    //Buscar facturas

    const busquedaAvanzada = async (values) => {
        setSpinTitle(t('global.loading'));
        setSpinning(true);

        // Validación para asignar valor null o vacío en caso de undefined o vacío
        const rdatereserve = values.rdatereserve ? values.rdatereserve.format('DD/MM/YYYY') : null;
        const codigo = values.codigo || '';
        const cname = values.cname || '';
        const sname = values.sname || '';

        Axios.post("sd/getFacturas",
            {
                "filtros": {
                    "rdatereserve": rdatereserve,
                    "codigo": codigo,
                    "cname": cname,
                    "sname": sname
                }
            },
            {
                headers: headers
            }
        ).then(response => {
            setSpinning(false);

            const rawData = response.data.data;

            // Formatear los datos
            const formattedData = rawData.map(item => ({
                code: item.code,
                rdatereserve: item.rdatereserve,
                cname: item.cname,
                cidentity: item.cidentity,
                address: item.address,
                cprovince: item.cprovince,
                cmunicipality: item.cmunicipality,
                sname: item.sname,
                products: JSON.parse(item.products)  // Asegúrate de que el valor sea un array
            }));

            setData(formattedData);

        }).catch(error => {
            setSpinning(false);
        });
    };


    const onFinishBuscarFactura = (values) => {
        busquedaAvanzada(values);
        setIsModalBuscarFacturaOpen(false); // Cerrar el modal después de buscar
    };

    const showModalBuscarFactura = () => {
        setIsModalBuscarFacturaOpen(true);
    };

    const handleCancelBuscarFactura = () => {
        formBuscarFactura.resetFields();
        setIsModalBuscarFacturaOpen(false); // Cerrar el modal si se cancela
    };

    return (
        <Spin spinning={spinning} tip={spinTitle} size={"large"}>

            <div style={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                //alignItems: 'center',
                padding: '10px'
            }}>
                <p style={{
                    textAlign: "center",
                    margin: "10px 0",
                    fontSize: "24px",
                    fontWeight: "bold",
                    color: "#2c3e50",
                    fontFamily: "'Roboto', sans-serif",
                    borderBottom: "2px solid #61768B",
                    paddingBottom: "5px",
                    textShadow: "1px 1px 2px rgba(0,0,0,0.2)"
                }}>
                    {t('invoice.title')}
                </p>

                {contextHolder}

                <Space direction='horizontal'>
                    <Tooltip
                        title={t('invoice.search')}>
                        <Button style={{marginRight: "0px", marginLeft: "10px"}} type="primary" shape="circle"
                                className="button" icon={<CloudDownloadOutlined />} onClick={showModal}>

                        </Button>
                    </Tooltip>
                    <Tooltip
                        title={t('global.search')}>
                        <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                className="button" icon={<SearchOutlined/>} onClick={showModalBuscarFactura}>

                        </Button>
                    </Tooltip>
                    <Tooltip title={t('invoice.update')}>
                        <Button style={{marginRight: "0px", marginLeft: "0px"}} type="primary" shape="circle"
                                className="button" icon={<IoReload/>} onClick={recargar} loading={loading}>

                        </Button>
                    </Tooltip>
                </Space>

                {/*Modal para imortar las facturas*/}
                <Modal
                    title={<div style={{ textAlign: 'center', width: '100%' }}>{t('invoice.search_form')}</div>}
                    open={isModalOpen}
                    onCancel={handleCancel}
                    footer={null}
                >
                    <Form form={form} onFinish={onFinish} layout="vertical">
                        <Form.Item
                            name="enterprise"
                            label={t('invoice.enterprise')}
                            initialValue={"etcexpress"}
                            rules={[{required: true, message: t('invoice.enterprise_required')}]}
                        >
                            <Input placeholder={t('invoice.enterprise')}/>
                        </Form.Item>
                        <Form.Item
                            name="dates"
                            label={t('invoice.dates_range')}
                            rules={[{required: true, message: t('invoice.dates_range_required')}]}
                        >
                            <RangePicker format="YYYY-MM-DD" style={{ width: '100%' }}/>
                        </Form.Item>
                        <Form.Item
                            name="status"
                            label={t('invoice.status')}
                        >
                            {/*<Input placeholder={t('invoice.status_optional')}/>*/}
                            <StatusFacturas placeholder={t('invoice.status_optional')}/>
                        </Form.Item>

                        <Form.Item>
                            <div style={{textAlign: 'center'}}>
                                <Button type="default" icon={<CloseOutlined/>} danger onClick={handleCancel} style={{marginRight: 8}}>
                                    {t('global.cancel')}
                                </Button>
                                <Button type="primary" icon={<ImportOutlined />} htmlType="submit" loading={loading}>
                                    {t('global.import')}
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>

                {/*Modal para realizar busquedas de las facturas*/}
                <Modal
                    title={<div style={{ textAlign: 'center', width: '100%' }}>{t('invoice.advanced_invoice_search')}</div>}
                    open={isModalBuscarFacturaOpen}
                    onCancel={handleCancelBuscarFactura}
                    footer={null}
                >
                    <Form form={formBuscarFactura} onFinish={onFinishBuscarFactura} layout="vertical">
                        <Form.Item
                            name="rdatereserve"
                            label={t('invoice.form_fecha_reserva')}
                            rules={[{ required: false, message: 'La fecha de reserva es obligatoria' }]}
                        >
                            <DatePicker format="DD/MM/YYYY" style={{ width: '100%' }} placeholder={t('invoice.form_fecha_reserva')}/>
                        </Form.Item>

                        <Form.Item
                            name="codigo"
                            label={t('invoice.form_identificador')}
                            rules={[{ required: false, message: 'El identificador de factura es obligatorio' }]}
                        >
                            <Input placeholder={t('invoice.form_identificador')} />
                        </Form.Item>

                        <Form.Item
                            name="sname"
                            label={t('invoice.form_remitente')}
                            rules={[{ required: false, message: 'El remitente es obligatorio' }]}
                        >
                            <Input placeholder={t('invoice.form_remitente')} />
                        </Form.Item>

                        <Form.Item
                            name="cname"
                            label={t('invoice.form_destinatario')}
                            rules={[{ required: false, message: 'El destinatario es obligatorio' }]}
                        >
                            <Input placeholder={t('invoice.form_destinatario')} />
                        </Form.Item>

                        <Form.Item>
                            <div style={{ textAlign: 'center' }}>
                                <Button type="default" danger onClick={handleCancelBuscarFactura} style={{ marginRight: 8 }}>
                                    {t('global.cancel')}
                                </Button>
                                <Button type="primary" htmlType="submit" loading={loading}>
                                    {t('global.acept')}
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </Modal>


                <Modal
                    // title={`Detalles de Factura ${selectedInvoice?.code}`}
                    title={<div style={{ textAlign: 'center', width: '100%' }}>{t('invoice.invoice_details')}{` ${selectedInvoice?.code}`}</div>}
                    open={isModalOpenDetalles}
                    onCancel={handleCancelVerDetalles}
                    footer={null}
                    width={'50%'} // Ajusta el ancho aquí
                >
                    {selectedInvoice && (
                        <div>
                            <p><strong>{t('invoice.col_fecha_reserva')}:</strong> {selectedInvoice.rdatereserve}</p>
                            <p><strong>{t('invoice.col_destinatario')}:</strong> {selectedInvoice.cname}</p>
                            <p><strong>{t('invoice.col_identidad')}:</strong> {selectedInvoice.cidentity}</p>
                            <p><strong>{t('invoice.col_direccion')}:</strong> {selectedInvoice.address}</p>
                            <p><strong>{t('invoice.col_provincia')}:</strong> {selectedInvoice.cprovince}</p>
                            <p><strong>{t('invoice.col_municipio')}:</strong> {selectedInvoice.cmunicipality}</p>
                            <p><strong>{t('invoice.col_remitente')}:</strong> {selectedInvoice.sname}</p>

                            {/* Mostrar productos de la factura */}
                            <Table
                                columns={productColumns}
                                dataSource={selectedInvoice.products}
                                pagination={false}
                                rowKey="namegood"
                            />
                        </div>
                    )}
                </Modal>

                {error && <div>Error: {error}</div>}
                <Table
                    dataSource={data}
                    columns={columns}
                    rowKey="code"
                    bordered={true}
                    expandable={{expandedRowRender}}
                    onChange={handleTableChange}
                    pagination={{
                        position: [bottom],
                        current: pagination.current,
                        pageSize: pagination.pageSize,
                        showSizeChanger: true,
                        pageSizeOptions: ['20', '40', '60', '80', '100'],
                        // pageSizeOptions: ['10', '20', '50', '100'],
                        showTotal: (total) => `Total ${total} elementos`, // Texto que muestra el total de elementos
                    }}
                />
            </div>
        </Spin>
    );
}

export default Facturas;
