import React, {useContext, useState, useEffect} from 'react'
import {Image, Menu } from "antd";
import {Link } from "react-router-dom";
import {
    LogoutOutlined, UserOutlined, ContainerFilled, CarFilled, TrademarkOutlined,
    CopyrightOutlined, ContainerOutlined, SendOutlined, RightOutlined, RocketOutlined,
    MailOutlined, BarsOutlined, ControlOutlined, CloseCircleOutlined, CalculatorOutlined,
    ProductOutlined, MediumOutlined, AuditOutlined, UsergroupAddOutlined, TruckOutlined,
    GiftOutlined, ShoppingCartOutlined, AimOutlined, HomeOutlined
} from '@ant-design/icons';

import {LoginContext} from "../components/seguridad/servicios/LoginServicios";
import { FormattedMessage } from 'react-intl';
import {useTranslation} from "react-i18next";
// import TipoRuta from "../modulos/tiporuta/TipoRuta";
// import FormAddTipoRuta from "../modulos/tiporuta/FormAddTipoRuta";

const { SubMenu } = Menu;

const REACT_APP_LOGO = process.env.REACT_APP_LOGO;

const MenuPrincipal = ({collapsed,...props}) => {
    
    const {logout} = useContext(LoginContext);

    const [openKeys, setOpenKeys] = useState([]);

    const {t, i18n} = useTranslation();


    let funcionalidades = (sessionStorage.getItem('funcionalidades'))? JSON.parse(sessionStorage.getItem('funcionalidades')):null;
            

    useEffect(() => {
        if(funcionalidades != null){
            //abre por defecto el menu Usuarios
            // setOpenKeys(funcionalidades.slice(0,1));
        }

    }, []);  
         

    const onOpenChange = (keys) => {
        const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
        if (latestOpenKey && funcionalidades.indexOf(latestOpenKey) === -1) {
            setOpenKeys(keys);
        } else {
            setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
        }
    };

    const onMenuClick = (key) =>{
       
        if(key === '0') {
            logout();
        }
    
    };

 
	return ( 
		<Menu className = {`${collapsed ? 'collapseStyle' :'expandedStyle'}`}
           // scroll={{
           //     y: 400,
           // }}
           openKeys={openKeys}
           onOpenChange={onOpenChange}
           mode="inline"
           onClick={(e) => onMenuClick(e.key)}
           style={{
               overflowY: 'auto',
               height: '100%',
               borderRight: 0,
               backgroundColor: '#38516D',
               color: '#ffffff',
               // fontWeight: 'bold',  // Estilo de letra en negrita
               // width: collapsed ? '80px' : '220px'  //ancho fijo para el menu
           }}
           >
           <div flex ="auto" >
               <Image
                   // width={200}
                   // height={200}
                   preview={false}
                   src={REACT_APP_LOGO} />
           </div>

           {/* </SubMenu>*/}
           {/* <Menu.Item key='00' className="homeMenu">*/}
           {/*     <HomeOutlined style={{color:'white'}}/>*/}
           {/*     <span>*/}
           {/*         <Link to="/principal">*/}
           {/*             {<FormattedMessage id="menu_cerrar_session" defaultMessage="Inicio" />}*/}
           {/*          </Link>*/}
           {/*     </span>*/}
           {/* </Menu.Item>*/}

           <SubMenu hidden={funcionalidades.indexOf("menu_persona")===-1}   key="menu_persona"  icon={<UsergroupAddOutlined style={{color:'white'}}/>}
                title={<FormattedMessage id="menu_persona" defaultMessage="Personas" />}>

                <Menu.Item key="2">
                    <UsergroupAddOutlined />
                    <span ><Link to="/persona">
                   {<FormattedMessage id="sub_menu_persona" defaultMessage="Personas" />}
                     </Link></span>
                </Menu.Item>

            </SubMenu>

            <SubMenu hidden={funcionalidades.indexOf("menu_paquetes")===-1}   key="menu_paquetes"  icon={<GiftOutlined style={{color:'white'}}/>}
                     title={<FormattedMessage id="menu_paquetes" defaultMessage="Paquetes" />}>

                <Menu.Item key="20">
                    <GiftOutlined />
                    <span ><Link to="/facturas">
                   {<FormattedMessage id="sub_menu_paquetes" defaultMessage="Paquetes" />}
                     </Link></span>
                </Menu.Item>
            </SubMenu>

            <SubMenu hidden={funcionalidades.indexOf("menu_entregas")===-1}   key="menu_entregas"  icon={<ShoppingCartOutlined style={{color:'white'}}/>}
                     title={<FormattedMessage id="menu_entregas" defaultMessage="Entregas" />}>

                <Menu.Item key="30">
                    <AimOutlined />
                    <span ><Link to="/mapa">
                   {<FormattedMessage id="sub_menu_entregas" defaultMessage="Localización" />}
                     </Link></span>
                </Menu.Item>
                <Menu.Item key="31">
                    <TrademarkOutlined />
                    <span ><Link to="/principal">
                   {<FormattedMessage id="sub_menu_entregas" defaultMessage="Rutas" />}
                     </Link></span>
                </Menu.Item>

            </SubMenu>

            <SubMenu hidden={funcionalidades.indexOf("menu_vehiculos")===-1}   key="menu_vehiculos"  icon={<TruckOutlined style={{color:'white'}}/>}
                     title={<FormattedMessage id="menu_vehiculos" defaultMessage="Vehículos" />}>

                <Menu.Item key="40">
                    <CarFilled />
                    <span ><Link to="/tipovehiculo">
                   {<FormattedMessage id="sub_menu_tipos_vehiculos" defaultMessage="Tipos" />}
                     </Link></span>
                </Menu.Item>
                <Menu.Item key="41">
                    <TruckOutlined />
                    <span ><Link to="/vehiculo">
                   {<FormattedMessage id="sub_menu_gestionar_vehiculos" defaultMessage="Gestionar" />}
                     </Link></span>
                </Menu.Item>

            </SubMenu>

            <SubMenu hidden={funcionalidades.indexOf("menu_auditoria")===-1}   key="menu_auditoria"  icon={<AuditOutlined style={{color:'white'}}/>}
                     title={t('auditoria.component_name')}>

                <Menu.Item key="50">
                    <CarFilled />
                    <span ><Link to="/auditoriavehiculo">
                        {t('auditoria.sub_menu_auditoria_vehiculos')}
                     </Link></span>
                </Menu.Item>

                {/*<Menu.Item key="51">*/}
                {/*    <TruckOutlined />*/}
                {/*    <span ><Link to="/vehiculo">*/}
                {/*        {t('auditoria.sub_menu_auditoria_personas')}*/}
                {/*     </Link></span>*/}
                {/*</Menu.Item>*/}

            </SubMenu>



            {/*<SubMenu hidden={funcionalidades.indexOf("menu_mzproducts")===-1}   key="menu_mzproducts"  icon={<MediumOutlined style={{color:'white'}}/>}*/}
            {/*    title={<FormattedMessage id="menu_mzproducts" defaultMessage="MZProducts" />}>*/}

            {/*    <Menu.Item key="7" >*/}
            {/*        <ProductOutlined />*/}
            {/*        <span  ><Link to="/tracking">*/}
            {/*            {<FormattedMessage id="sub_menu_tipo_rutas" defaultMessage="Tracking" />}*/}
            {/*                </Link></span>*/}
            {/*    </Menu.Item>*/}
            {/*    <Menu.Item key="5">*/}
            {/*        <AuditOutlined />*/}
            {/*        <span ><Link to="/invoices">*/}
            {/*       {<FormattedMessage id="sub_menu_gestionar_ruta" defaultMessage="Facturas" />}*/}
            {/*         </Link></span>*/}
            {/*    </Menu.Item>*/}
            {/*</SubMenu>*/}

            <SubMenu hidden={funcionalidades.indexOf("menu_usuarios")===-1}   key="menu_usuarios"  icon={<UserOutlined style={{color:'white'}}/>}
                     title={<FormattedMessage id="menu_admin_user" defaultMessage="Usuarios" />}>
                <Menu.Item key="1" >
                    <UserOutlined />
                    <span  ><Link to="/usuarios">{<FormattedMessage id="sub_menu_users" defaultMessage="Usuarios" />}</Link></span>
                </Menu.Item>
            </SubMenu>

           <Menu.Item key='0'>
               <LogoutOutlined style={{color:'white'}}/>
               <span>{<FormattedMessage id="menu_cerrar_session" defaultMessage="Cerrar Sesión" />}</span>
           </Menu.Item>

       </Menu>
    );
}
 
export default MenuPrincipal;